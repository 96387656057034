import { Route } from "react-router-dom";
import Login from "./pages/LogIn";
import Dashboard from "./pages/Dashboard";
import CreateUser from "./pages/CreateUser";
import UpdateUser from "./components/UpdateUser";
import UserList from "./pages/UserList";
import Settings from "./pages/Settings";
import NewDashboard from "./pages/NewDashboard/Index";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import GroupIcon from "@mui/icons-material/Group";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HistoryIcon from "@mui/icons-material/History";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {
  DashboardOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  UserAddOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import Uploads from "./pages/Upload/Upload";
import { AttachFile, UploadFile } from "@mui/icons-material";
import PowerBI from "./pages/PowerBI";
import EditPowerBI from "./pages/PowerBI/EditPowerBI";
import ProdgainIntegration from "./pages/ProdgainIntegration";
import ProcessedReportsLogger from "./components/Prodgain/ProcessedReportsLogger";

export const publicRoutes = [
  <Route exact={true} path="/" element={<Login />} />,
  <Route exact={true} path="/login" element={<Login />} />,
];

export const privateRoutes = [
  <Route exact={true} path="/dashboard" element={<Dashboard />} />,
  <Route exact={true} path="/create-user" element={<CreateUser />} />,
  <Route exact={true} path="/update-user" element={<UpdateUser />} />,
  <Route exact={true} path="/user-list" element={<UserList />} />,
  <Route exact={true} path="/settings" element={<Settings />} />,
  <Route exact={true} path="/uploads" element={<Uploads />} />,
  <Route exact={true} path="/new-dash" element={<NewDashboard />} />,
];

export const publicRoutesMeta = [
  {
    path: "/",
    title: "Login",
    component: Login,
  },
  {
    path: "/login",
    title: "Login",
    component: Login,
  },
];

export const privateRoutesMeta = [
  {
    path: "dashboard",
    key: "dashboard",
    title: (
      <span style={{ fontSize: "14px" }}>
        <DashboardOutlined
          style={{
            fontSize: "14px",
          }}
        />
        <span>Dashboard</span>
      </span>
    ),
    component: Dashboard,
  },

  {
    path: "user-management",
    title: (
      <span
        style={{
          fontSize: "14px",
        }}
      >
        <UserAddOutlined
          style={{
            fontSize: "14px",
          }}
        />
        <span>User Management</span>
      </span>
    ),
    key: "user-management",
    child: [
      {
        path: "user-management/report-assignment",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Report Assignment <AssignmentIndIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "report-assignment",
        component: Settings,
      },
      {
        path: "user-management/create-user",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <UserAddOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Create Synapse User <PersonAddIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "create-user",
        component: Settings,
      },
      {
        path: "user-management/users-list",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Synapse Users List <GroupIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "users-list",
        component: Settings,
      },
      {
        path: "user-management/create-cms-user",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Create CMS User <PersonAddIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "create-cms-user",
        component: Settings,
      },
      {
        path: "user-management/cms-users-list",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            CMS Users List <GroupIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "cms-users-list",
        component: Settings,
      },
    ],
  },
  {
    path: "content-management",
    title: (
      <span
        style={{
          fontSize: "14px",
        }}
      >
        <ReconciliationOutlined
          style={{
            fontSize: "14px",
          }}
        />
        <span>Content Management</span>
      </span>
    ),
    key: "content-management",
    child: [
      {
        path: "content-management/view-and-edit-reports",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <RocketOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            View & Edit Reports <EditIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "view-and-edit-reports",
        component: Settings,
      },
      {
        path: "content-management/create-report",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Create New Report <AddIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "create-report",
        component: Settings,
      },
      {
        path: "content-management/upload-files-1",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Upload Files <FileUploadIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "upload-files-1",
        component: Settings,
      },
      {
        path: "content-management/bulk-upload",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Bulk Upload <UploadFile sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "bulk-upload",
        component: Settings,
      },
    ],
  },
  {
    path: "custom-reports-management",
    title: (
      <span
        style={{
          fontSize: "14px",
        }}
      >
        <FileDoneOutlined
          style={{
            fontSize: "14px",
          }}
        />
        <span>Custom Reports Management</span>
      </span>
    ),
    key: "custom-reports-management",
    child: [
      {
        path: "custom-reports-management/upload-files-2",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <RocketOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Upload Files <FileUploadIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "upload-files-2",
        component: Settings,
      },
      {
        path: "custom-reports-management/attach-reports",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Attach Reports <AttachFile sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "attach-reports",
        component: Settings,
      },
      // {
      //   path: "custom-reports-management/assign-report",
      //   title: (
      //     <span
      //       style={{
      //         fontSize: "14px",
      //       }}
      //     >
      //       {/* <ReconciliationOutlined
      //         style={{
      //           fontSize: "14px",
      //         }}
      //       /> */}
      //       <span>Assign Report</span>
      //     </span>
      //   ),
      //   key: "assign-report",
      //   component: Settings,
      // },
    ],
  },
  {
    path: "settings",
    title: (
      <span
        style={{
          fontSize: "14px",
        }}
      >
        <SettingOutlined
          style={{
            fontSize: "14px",
          }}
        />
        <span>Settings</span>
      </span>
    ),
    key: "settings",
    child: [
      {
        path: "settings/view-login-history",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <RocketOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            View Login History <HistoryIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "view-login-history",
        component: Settings,
      },
      {
        path: "settings/change-password",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Change Password <VpnKeyIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "change-password",
        component: Settings,
      },
    ],
  },
  // {
  //   path: "bi-integration",
  //   title: (
  //     <span
  //       style={{
  //         fontSize: "14px",
  //       }}
  //     >
  //       <SettingOutlined
  //         style={{
  //           fontSize: "14px",
  //         }}
  //       />
  //       <span>BI Integration</span>
  //     </span>
  //   ),
  //   key: "bi-integration",
  //   component: PowerBI,
  // },

  {
    path: "ai-processing",
    child: [
      {
        path: "ai-processing/process-reports",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            Process Reports <FileUploadIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "process-reports",
        component: ProdgainIntegration,
      },
      {
        path: "ai-processing/processed-reports-log",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            Reports Logs <HistoryIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "processed-reports-log",
        component: ProcessedReportsLogger,
      },
    ],
  },
  {
    path: "power-bi-integration",
    child: [
      {
        path: "power-bi-integration/add-dashboards",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <RocketOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Add Dashboards <HistoryIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "add-dashboards",
        component: PowerBI,
      },
      {
        path: "power-bi-integration/edit-dashboards",
        title: (
          <span
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/* <ReconciliationOutlined
              style={{
                fontSize: "14px",
              }}
            /> */}
            Manage Dashboards <VpnKeyIcon sx={{ marginLeft: "5px" }} />
          </span>
        ),
        key: "edit-dashboards",
        component: EditPowerBI,
      },
    ],
  },
];
