import {
  Container,
  Box,
  Typography,
  Button,
  Alert,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import API from "../../../utils/api";
import Cookies from "universal-cookie";
import axios from "axios";

function AccessUser({ userData }) {
  const cookies = new Cookies();

  const [otp, setOtp] = useState(null);
  const [ableToEnterOTP, setAbleToEnterOTP] = useState(false);
  const [disableRequestButton, setDisableRequestButton] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    color: "info",
    message: `Get ${userData.name} Credentials`,
  });
  const [loading, setLoading] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  useEffect(() => {
    let timer;
    if (resendTimer > 0 && ableToEnterOTP) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer, ableToEnterOTP]);

  const apiConfig = {
    method: "POST",
    headers: { authorization: `Bearer ${cookies.get("TOKEN")}` },
  };

  const sendRequestToAccessUser = async () => {
    setLoading(true);
    try {
      const res = await axios({
        ...apiConfig,
        url: `${API.Base_URL}salesUser/client-access-verification-request`,
        data: { client_email: userData.email },
      });
      setStatusMessage({ color: "success", message: res.data.message });
      setAbleToEnterOTP(true);
      setResendTimer(60); // Reset timer
    } catch (err) {
      console.error(err);
      setStatusMessage({
        color: "error",
        message: err.response.data?.message || err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const verifyClientAndSendCredentials = async () => {
    setLoading(true);
    try {
      const res = await axios({
        ...apiConfig,
        url: `${API.Base_URL}salesUser/send-client-credentials`,
        data: { otp, client_email: userData.email },
      });
      setDisableRequestButton(true);
      setStatusMessage({ color: "success", message: res.data.message });
      setTimeout(() => {
        setDisableRequestButton(false);
        setStatusMessage({
          color: "info",
          message: `Get ${userData.name} Credentials`,
        });
      }, 4500);
      setAbleToEnterOTP(false);
      setOtp(null);
      setIsDeclarationChecked(false);
    } catch (err) {
      console.error(err);
      setStatusMessage({
        color: "error",
        message: err.response.data?.message || err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{ bgcolor: "white", p: 4, borderRadius: 2, boxShadow: 3 }}
      >
        <Typography variant="h4" gutterBottom textAlign="center">
          Access User
        </Typography>

        <Box sx={{ textAlign: "center", my: 4 }}>
          {!ableToEnterOTP && (
            <Button
              variant="contained"
              color="primary"
              onClick={sendRequestToAccessUser}
              disabled={loading || disableRequestButton}
              sx={{ minWidth: "150px" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Request Credentials"
              )}
            </Button>
          )}

          {ableToEnterOTP && (
            <Box>
              <TextField
                label="Enter OTP"
                type="number"
                value={otp}
                onChange={(e) => {
                  if (e.target.value.length <= 4) setOtp(e.target.value);
                }}
                inputProps={{ min: 1000, max: 9999 }}
                fullWidth
                margin="normal"
                sx={{
                  "& input": {
                    textAlign: "center",
                  },
                }}
              />
              <Box
                sx={{
                  p: 2,
                  mt: 2,
                  bgcolor: "#f5f5f5",
                  borderRadius: 2,
                  fontSize: "0.9rem",
                  color: "rgba(0, 0, 0, 0.7)",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDeclarationChecked}
                      onChange={(e) =>
                        setIsDeclarationChecked(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "justify", fontSize: "0.8rem" }}
                    >
                      "I hereby declare that these credentials will only be used
                      for verifying the account functionality created for the
                      client. I understand that I am responsible for ensuring
                      the confidentiality of these details and acknowledge that
                      any misuse or unauthorized sharing may result in harm to
                      Mordor Intelligence or a breach of client privacy."
                    </Typography>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={verifyClientAndSendCredentials}
                  disabled={loading || !isDeclarationChecked}
                  sx={{
                    minWidth: "120px",
                    fontSize: "0.875rem",
                    px: 3,
                    py: 1,
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Verify OTP"
                  )}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={sendRequestToAccessUser}
                  disabled={resendTimer > 0}
                  sx={{
                    minWidth: "120px",
                    fontSize: "0.875rem",
                    px: 3,
                    py: 1,
                  }}
                >
                  {resendTimer > 0
                    ? `Resend OTP in ${resendTimer}s`
                    : "Resend OTP"}
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        <Alert severity={statusMessage.color} sx={{ mt: 2 }}>
          {statusMessage.message}
        </Alert>
      </Container>
    </Box>
  );
}

export default AccessUser;
