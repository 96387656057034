import UserForm from "./UserForm";
import AccessUser from "./AccessUser";
import { Button } from "antd";
import { Box } from "@mui/material";
import { LeftOutlined, KeyOutlined } from "@ant-design/icons";
import { useState } from "react";

function NewCreateUser({ userData, setToSearchScreen }) {
  const [accessUser, setAccessUser] = useState(false);

  return (
    <div>
      {userData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            icon={<LeftOutlined />}
            onClick={() => {
              if (accessUser) {
                setAccessUser(false);
                return;
              }
              setToSearchScreen(true);
            }}
          >
            Go To {accessUser ? "Edit User" : "User List"}
          </Button>
          {accessUser === false && (
            <Button
              icon={<KeyOutlined />}
              onClick={() => {
                setAccessUser(true);
              }}
            >
              Access User
            </Button>
          )}
        </Box>
      )}
      {accessUser ? (
        <AccessUser {...{ userData }} />
      ) : (
        <UserForm {...{ userData, setToSearchScreen }} />
      )}
    </div>
  );
}

export default NewCreateUser;
