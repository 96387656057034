import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  message,
  Descriptions,
  Spin,
  Modal,
  Tag,
  Alert,
} from "antd";
import axios from "axios";
import API from "../../utils/api";
import { renderPushedStatus } from "./helper";

function ReportDetails() {
  const [form] = Form.useForm();
  const [tocForm] = Form.useForm();
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [polling, setPolling] = useState(false);
  const [showTocModal, setShowTocModal] = useState(false);
  const [pollingEnabled, setPollingEnabled] = useState(false); // State for polling toggle
  const [uploadId, setUploadId] = useState(null);
  const { prodgainApiBaseUrl, prodgainAuthToken } = API;

  const fetchReport = async (values) => {
    setLoading(true);
    setReportData(null); // Clear previous data
    try {
      const response = await axios.get(
        `${prodgainApiBaseUrl}/report/${values.uploadId}`,
        {
          headers: {
            Authorization: prodgainAuthToken,
          },
        }
      );
      const { data } = response.data;
      setUploadId(values.uploadId);
      setReportData(data); // Update the report data
      if (data.status === "needs_toc") setShowTocModal(true);
      else if (data.status === "failed")
        message.error("Report processing failed.");
      else if (data.status === "processed")
        message.success("Report processed successfully.");
    } catch (error) {
      setUploadId(null);
      message.error(error.response?.data?.message || "Failed to fetch report.");
    } finally {
      setLoading(false);
    }
  };

  const submitToc = async (values) => {
    try {
      setLoading(true);
      await axios.post(
        `${prodgainApiBaseUrl}/${reportData.uploadId}/set-toc-slide`,
        {
          slideStart: values.slideStart,
          slideEnd: values.slideEnd,
        },
        {
          headers: {
            Authorization: prodgainAuthToken,
          },
        }
      );
      message.success("TOC slide numbers updated successfully.");
      setShowTocModal(false);
      fetchReport({ uploadId: reportData.uploadId }); // Re-fetch report status
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to update TOC.");
    } finally {
      setLoading(false);
    }
  };

  // Polling function to retry the API call
  useEffect(() => {
    let intervalId;
    if (pollingEnabled) {
      intervalId = setInterval(async () => {
        try {
          const uploadId = form.getFieldValue("uploadId");
          const response = await axios.get(
            `${prodgainApiBaseUrl}/report/${uploadId}`,
            {
              headers: {
                Authorization: prodgainAuthToken,
              },
            }
          );
          const { data } = response.data;
          setReportData(data);
          if (data.status === "failed") {
            message.error("Report processing failed.");
          } else {
            message.success("Report status Refreshed")
          }
        } catch (error) {
          message.error(
            error.response?.data?.message || "Error during polling."
          );
        }
      }, 40000); // Poll every 40 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [pollingEnabled, form, prodgainApiBaseUrl, prodgainAuthToken]);

  const togglePolling = () => {
    setPollingEnabled((prev) => !prev);
    if (!pollingEnabled) {
      message.info("Polling enabled: Refreshing status every 40 seconds.");
    } else {
      message.info("Polling disabled: Automatic refresh turned off.");
    }
  };

  return (
    <Card title="Report Details" style={{ marginBottom: "20px" }}>
      <Form
        form={form}
        layout="inline"
        onFinish={fetchReport}
        style={{ marginBottom: "20px" }}
      >
        <Form.Item
          label="Upload ID"
          name="uploadId"
          rules={[{ required: true, message: "Please input the upload ID!" }]}
        >
          <Input placeholder="Enter Upload ID" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Get Report
          </Button>
        </Form.Item>
      </Form>

      <Button
        type={pollingEnabled ? "default" : "primary"}
        onClick={togglePolling}
        style={{ marginBottom: "20px" }}
        disabled={uploadId === null}
      >
        {pollingEnabled ? "Disable Polling" : "Enable Polling"}
      </Button>

      {pollingEnabled && (
        <Alert
          message="Polling Enabled"
          description="Refreshing the status every 40 seconds."
          type="info"
          showIcon
          style={{ marginBottom: "20px" }}
        />
      )}

      {/* Loading Spinner */}
      {loading && <Spin style={{ display: "block", marginTop: "20px" }} />}

      {/* Report Details UI */}
      {reportData && (
        <Descriptions
          bordered
          column={1}
          size="middle"
          style={{ marginTop: "20px" }}
        >
          <Descriptions.Item label="Report ID">
            {reportData.reportId}
          </Descriptions.Item>
          <Descriptions.Item label="Upload ID">
            {reportData.uploadId}
          </Descriptions.Item>
          <Descriptions.Item label="Current Stage">
            {reportData.currentStageName}
          </Descriptions.Item>
          <Descriptions.Item label="Report Name">
            {reportData.reportName}
          </Descriptions.Item>
          <Descriptions.Item label="Slug">
            {reportData.slug || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Total Pages">
            {reportData.totalPages}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={reportData.status === "processed" ? "green" : "blue"}>
              {reportData.status}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Countries Covered">
            {reportData.countriesCovered.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Regions Covered">
            {reportData.regionsCovered.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Show on Synapse">
            {reportData.showOnSynapse ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Is Exclusive">
            {reportData.isExclusive ? "Yes" : "No"}
          </Descriptions.Item>
          {reportData.isExclusive && (
            <Descriptions.Item label="User Id">
              {reportData.clientUserId}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Pushed Status">
            <Descriptions bordered column={1} size="middle">
              <Descriptions.Item label="Dashboard">
                {renderPushedStatus(reportData?.pushedToMordor?.pushDashboard)}
              </Descriptions.Item>
              <Descriptions.Item label="TOC">
                {renderPushedStatus(reportData?.pushedToMordor?.pushToc)}
              </Descriptions.Item>
              <Descriptions.Item label="Executive Summary">
                {renderPushedStatus(
                  reportData?.pushedToMordor?.pushExecSummary
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Snippets">
                {renderPushedStatus(reportData?.pushedToMordor?.pushSnippets)}
              </Descriptions.Item>
              <Descriptions.Item label="Graphs">
                {renderPushedStatus(reportData?.pushedToMordor?.pushGraphs)}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>
      )}

      {/* TOC Modal */}
      <Modal
        title="Set Table of Contents Slide Numbers"
        visible={showTocModal}
        onCancel={() => setShowTocModal(false)}
        footer={null}
      >
        <Form form={tocForm} onFinish={submitToc}>
          <Form.Item
            label="Slide Start"
            name="slideStart"
            rules={[
              { required: true, message: "Please enter the starting slide!" },
            ]}
          >
            <Input type="number" placeholder="Enter starting slide number" />
          </Form.Item>
          <Form.Item
            label="Slide End"
            name="slideEnd"
            rules={[
              { required: true, message: "Please enter the ending slide!" },
            ]}
          >
            <Input type="number" placeholder="Enter ending slide number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit TOC
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

export default ReportDetails;
