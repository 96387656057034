import { useState, useEffect } from "react";
import { Descriptions, Spin, Typography, message, Tag } from "antd";
import axios from "axios";
import API from "../../utils/api";
import { renderPushedStatus } from "./helper";

function RowDetails({ uploadId }) {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API.prodgainApiBaseUrl}/report/${uploadId}`,
          {
            headers: {
              Authorization: API.prodgainAuthToken,
            },
          }
        );
        const { data } = response.data;
        setReportData(data);
      } catch (error) {
        message.error("Failed to fetch additional reportData.");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [uploadId]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!reportData) {
    return (
      <Typography.Text type="danger">
        No additional reportData found.
      </Typography.Text>
    );
  }

  return (
    <Descriptions
      bordered
      column={1}
      size="middle"
      style={{ marginTop: "20px" }}
    >
      <Descriptions.Item label="Report ID">
        {reportData.reportId}
      </Descriptions.Item>
      <Descriptions.Item label="Upload ID">
        {reportData.uploadId}
      </Descriptions.Item>
      <Descriptions.Item label="Current Stage">
        {reportData.currentStageName}
      </Descriptions.Item>
      <Descriptions.Item label="Report Name">
        {reportData.reportName}
      </Descriptions.Item>
      <Descriptions.Item label="Slug">
        {reportData.slug || "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Total Pages">
        {reportData.totalPages}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        <Tag color={reportData.status === "processed" ? "green" : "blue"}>
          {reportData.status}
        </Tag>
      </Descriptions.Item>
      <Descriptions.Item label="Countries Covered">
        {reportData.countriesCovered.join(", ")}
      </Descriptions.Item>
      <Descriptions.Item label="Regions Covered">
        {reportData.regionsCovered.join(", ")}
      </Descriptions.Item>
      <Descriptions.Item label="Show on Synapse">
        {reportData.showOnSynapse ? "Yes" : "No"}
      </Descriptions.Item>
      <Descriptions.Item label="Is Exclusive">
        {reportData.isExclusive ? "Yes" : "No"}
      </Descriptions.Item>
      {reportData.isExclusive && (
        <Descriptions.Item label="User Id">
          {reportData.clientUserId}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Pushed Status">
        <Descriptions bordered column={1} size="middle">
          <Descriptions.Item label="Dashboard">
            {renderPushedStatus(reportData?.pushedToMordor?.pushDashboard)}
          </Descriptions.Item>
          <Descriptions.Item label="TOC">
            {renderPushedStatus(reportData?.pushedToMordor?.pushToc)}
          </Descriptions.Item>
          <Descriptions.Item label="Executive Summary">
            {renderPushedStatus(reportData?.pushedToMordor?.pushExecSummary)}
          </Descriptions.Item>
          <Descriptions.Item label="Snippets">
            {renderPushedStatus(reportData?.pushedToMordor?.pushSnippets)}
          </Descriptions.Item>
          <Descriptions.Item label="Graphs">
            {renderPushedStatus(reportData?.pushedToMordor?.pushGraphs)}
          </Descriptions.Item>
        </Descriptions>
      </Descriptions.Item>
    </Descriptions>
  );
}

export default RowDetails;
