import React from "react";
import { Layout } from "antd";
import UploadReportForm from "../../components/Prodgain/UploadReportForm";
import ReportDetails from "../../components/Prodgain/ReportDetails";
import RetryProcessButton from "../../components/Prodgain/RetryProcessButton";
import Actions from "../../components/Prodgain/Actions";

const { Content } = Layout;

function ProdgainIntegration() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ padding: "20px", backgroundColor: "#fff" }}>
        <UploadReportForm />
        <ReportDetails />
        <RetryProcessButton />
        <Actions />
      </Content>
    </Layout>
  );
}

export default ProdgainIntegration;
