import React, { useState } from "react";
import { Card, Button, Form, Input, message } from "antd";
import axios from "axios";
import API from "../../utils/api";

function RetryProcessButton() {
  const [loading, setLoading] = useState(false);
  const {prodgainApiBaseUrl, prodgainAuthToken } = API;

  const handleRetry = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${prodgainApiBaseUrl}/retry-process-report/${values.uploadId}`,
        {},
        {
          headers: {
            Authorization: prodgainAuthToken,
          },
        }
      );

      message.success(response.data.message || "Retry initiated successfully");
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to retry process.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title="Retry Processing" style={{ marginBottom: "20px" }}>
      <Form layout="inline" onFinish={handleRetry}>
        {/* Upload ID Input */}
        <Form.Item
          label="Upload ID"
          name="uploadId"
          rules={[{ required: true, message: "Please input the upload ID!" }]}
        >
          <Input placeholder="Enter Upload ID" />
        </Form.Item>

        {/* Retry Button */}
        <Form.Item>
          <Button
            type="primary"
            danger
            htmlType="submit"
            loading={loading}
          >
            Retry Process
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default RetryProcessButton;
