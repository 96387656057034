import { useState } from "react";
import {
  Card,
  Form,
  Input,
  Upload,
  Button,
  Checkbox,
  Row,
  Col,
  message,
  Space,
  Typography,
  Spin,
} from "antd";
import { InboxOutlined, CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import API from "../../utils/api";
import { sendProcessedReportDetailsToLogger } from "./helper";

const { Dragger } = Upload;

function UploadReportForm() {
  const [isExclusive, setIsExclusive] = useState(false);
  const [uploadId, setUploadId] = useState(null); // State to store uploadId
  const [pdfSize, setPdfSize] = useState(null); // State for PDF file size
  const [pptSize, setPptSize] = useState(null); // State for PPT file size
  const [loading, setLoading] = useState(false); // State for button loading
  const [form] = Form.useForm();
  const { prodgainApiBaseUrl, prodgainAuthToken } = API;

  const handleUpload = async (values) => {
    setLoading(true);
    const formData = new FormData();

    // Add files and form fields to formData
    formData.append("pdfFile", values.pdfFile[0]);
    formData.append("pptFile", values.pptFile[0]);
    formData.append("reportId", values.reportId);
    if (values.slug) formData.append("slug", values.slug);
    if (values.clientUserId) formData.append("clientUserId", values.clientUserId);
    formData.append("showOnSynapse", values.showOnSynapse || false);

    try {
      const response = await axios.post(
        `${prodgainApiBaseUrl}/upload-pdf-ppt-report`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: prodgainAuthToken,
          },
        }
      );
      sendProcessedReportDetailsToLogger(response.data.data); // Send report details to logger
      const { uploadId } = response.data.data; // Extract uploadId from response
      setUploadId(uploadId); // Store uploadId in state
      message.success("Files uploaded successfully");
    } catch (error) {
      console.error("Upload failed:", error);
      message.error(error.response?.data?.message || "Failed to upload files");
    } finally {
      setLoading(false);
    }
  };

  const handleExclusiveChange = (e) => {
    setIsExclusive(e.target.checked);
  };

  const copyToClipboard = () => {
    if (uploadId) {
      navigator.clipboard.writeText(uploadId);
      message.success("Upload ID copied to clipboard!");
    }
  };

  const formatFileSize = (size) => {
    if (!size) return "N/A";
    return `${(size / 1024 / 1024).toFixed(2)} MB`; // Convert to MB
  };

  return (
    <Card title="Upload Report" style={{ marginBottom: "20px" }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleUpload}
        initialValues={{
          showOnSynapse: false,
          isExclusive: false,
        }}
      >
        {/* Report ID */}
        <Form.Item
          label="Report ID"
          name="reportId"
          rules={[{ required: true, message: "Please input the report ID!" }]}
        >
          <Input placeholder="Enter Report ID" />
        </Form.Item>

        {/* Upload PDF and PPT in a Row */}
        <Row gutter={16}>
          {/* Upload PDF */}
          <Col xs={24} sm={12}>
            <Form.Item
              label="Upload PDF File"
              name="pdfFile"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                const fileList = Array.isArray(e) ? e : e && [e.file];
                if (fileList?.[0]) {
                  setPdfSize(fileList[0].size); // Set PDF file size
                }
                return fileList;
              }}
              rules={[{ required: true, message: "Please upload a PDF file!" }]}
            >
              <Dragger
                beforeUpload={() => false}
                maxCount={1}
                accept=".pdf"
                style={{
                  padding: "10px",
                  border: "1px dashed #d9d9d9",
                  height: "120px",
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p>Click or drag a PDF file to this area</p>
              </Dragger>
            </Form.Item>
            {pdfSize && (
              <Typography.Text type="secondary">
                PDF File Size: {formatFileSize(pdfSize)}
              </Typography.Text>
            )}
          </Col>

          {/* Upload PPT */}
          <Col xs={24} sm={12}>
            <Form.Item
              label="Upload PPT File"
              name="pptFile"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                const fileList = Array.isArray(e) ? e : e && [e.file];
                if (fileList?.[0]) {
                  setPptSize(fileList[0].size); // Set PPT file size
                }
                return fileList;
              }}
              rules={[{ required: true, message: "Please upload a PPT file!" }]}
            >
              <Dragger
                beforeUpload={() => false}
                maxCount={1}
                accept=".ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pptm"
                style={{
                  padding: "10px",
                  border: "1px dashed #d9d9d9",
                  height: "120px",
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p>Click or drag a PPT file to this area</p>
              </Dragger>
            </Form.Item>
            {pptSize && (
              <Typography.Text type="secondary">
                PPT File Size: {formatFileSize(pptSize)}
              </Typography.Text>
            )}
          </Col>
        </Row>

        {/* Exclusive Report Checkbox */}
        <Form.Item name="isExclusive" valuePropName="checked">
          <Checkbox onChange={handleExclusiveChange}>Exclusive Report</Checkbox>
        </Form.Item>

        {/* Exclusive Report - User ID Input */}
        {isExclusive && (
          <Form.Item
            label="User ID"
            name="clientUserId"
            rules={[{ required: true, message: "User ID is required!" }]}
          >
            <Input placeholder="Enter User ID for Exclusive Report" />
          </Form.Item>
        )}

        {/* Show on Synapse Checkbox */}
        <Form.Item name="showOnSynapse" valuePropName="checked">
          <Checkbox>Show on Synapse</Checkbox>
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            {loading ? <Spin /> : "Upload"}
          </Button>
        </Form.Item>
      </Form>

      {/* Display Upload ID with Copy Button */}
      {uploadId && (
        <Space direction="horizontal" align="center" style={{ marginTop: "20px" }}>
          <Typography.Text strong>Upload ID:</Typography.Text>
          <Typography.Text copyable={{ text: uploadId }}>{uploadId}</Typography.Text>
          <Button icon={<CopyOutlined />} onClick={copyToClipboard}>
            Copy
          </Button>
        </Space>
      )}
    </Card>
  );
}

export default UploadReportForm;
