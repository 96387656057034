import { Layout, Table, Typography, Input, Button, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { ReloadOutlined } from "@ant-design/icons";
import API from "../../utils/api";
import { loggerTableColumns } from "./helper";
import RowDetails from "./RowDetails";

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

function ProcessedReportsLogger() {
  const cookies = new Cookies();
  const [processedReports, setProcessedReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  async function fetchProcessedReports() {
    setLoading(true);
    try {
      const response = await axios.get(API.Base_URL + "v1/ai/process-logger", {
        headers: { authorization: `Bearer ${cookies.get("TOKEN")}` },
      });
      setProcessedReports(response.data.data);
    } catch (error) {
      console.error("Error fetching processed reports:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProcessedReports();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const handleExpandRow = (uploadId) => {
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(uploadId)
        ? prevKeys.filter((key) => key !== uploadId)
        : [...prevKeys, uploadId]
    );
  };

  const filteredReports = processedReports.filter((report) =>
    report.reportName.toLowerCase().includes(searchText) ||
    report.uploadId.toLowerCase().includes(searchText) ||
    report.reportId.toString().toLowerCase().includes(searchText)
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ padding: "20px", backgroundColor: "#fff" }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {/* Header */}
          <Space style={{ justifyContent: "space-between", width: "100%" }}>
            <Title level={3}>Processed Reports Logger</Title>
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              onClick={fetchProcessedReports}
              loading={loading}
            >
              Refresh
            </Button>
          </Space>

          {/* Search Bar */}
          <Search
            placeholder="Search by Report Name, Upload ID, or Report ID"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={handleSearch}
          />

          {/* Table */}
          {loading ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table
              dataSource={filteredReports}
              columns={loggerTableColumns(handleExpandRow)}
              expandable={{
                expandedRowRender: (record) => (
                  <RowDetails uploadId={record.uploadId} />
                ),
                rowExpandable: (record) => !!record.uploadId,
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => {
                  setExpandedRowKeys(
                    expanded
                      ? [...expandedRowKeys, record.uploadId]
                      : expandedRowKeys.filter((key) => key !== record.uploadId)
                  );
                },
              }}
              rowKey="uploadId"
              bordered
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
              }}
            />
          )}
        </Space>
      </Content>
    </Layout>
  );
}

export default ProcessedReportsLogger;
