import { Typography, Button, Tag } from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import API from "../../utils/api";

export const loggerTableColumns = (onExpandRow) => [
  {
    title: "Report Name",
    dataIndex: "reportName",
    key: "reportName",
    render: (text) => <Typography.Text>{text}</Typography.Text>,
  },
  {
    title: "Upload ID",
    dataIndex: "uploadId",
    key: "uploadId",
    render: (text) => (
      <Typography.Text copyable={{ text }}>{text}</Typography.Text>
    ),
  },
  {
    title: "Report ID",
    dataIndex: "reportId",
    key: "reportId",
  },
  {
    title: "User ID",
    dataIndex: "userId",
    key: "userId",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (timestamp) =>
      timestamp ? (
        <Typography.Text>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </Typography.Text>
      ) : (
        <Typography.Text>N/A</Typography.Text>
      ),
  },
  {
    title: "Show on Synapse",
    dataIndex: "showOnSynapse",
    key: "showOnSynapse",
    render: (value) =>
      value ? (
        <Typography.Text type="success">Yes</Typography.Text>
      ) : (
        <Typography.Text type="danger">No</Typography.Text>
      ),
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Button type="link" onClick={() => onExpandRow(record.uploadId)}>
        More Details
      </Button>
    ),
  },
];

export const renderPushedStatus = (status) =>
  status ? <Tag color="green">Pushed</Tag> : <Tag color="red">Not Pushed</Tag>;

export const sendProcessedReportDetailsToLogger = async (reportData) => {
  const cookies = new Cookies();
  const token = cookies.get("TOKEN");
  await axios.post(API.Base_URL + "v1/ai/process-logger", reportData, {
    headers: { authorization: `Bearer ${token}` },
  });
};
