import React from "react";
import Cookies from "universal-cookie";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoutes({ path, children }) {
  const cookies = new Cookies();
  const token = cookies.get("TOKEN");
  const role = cookies.get("ROLE");
  const roleAccessibility = {
    SUPER_ADMIN: [
      "home",
      "dashboard",
      "create-user",
      "update-user",
      "report-assignment",
      "users-list",
      "create-cms-user",
      "cms-users-list",
      "view-and-edit-reports",
      "create-report",
      "upload-files-1",
      "bulk-upload",
      "upload-files-2",
      "attach-reports",
      "assign-report",
      "view-login-history",
      "change-password",
      "power-bi-integration",
      "add-dashboards",
      "edit-dashboards",
      "ai-processing",
    ],
    SALES_MANAGER: [
      "home",
      "dashboard",
      "create-user",
      "update-user",
      "report-assignment",
      "users-list",
      "view-login-history",
      "change-password",
      "create-cms-user",
    ],
    SALES_EXECUTIVE: [
      "home",
      "dashboard",
      "create-user",
      "view-login-history",
      "change-password",
    ],
    FORMATTING_MANAGER: [
      "home",
      "dashboard",
      "view-and-edit-reports",
      "create-report",
      "upload-files-1",
      "bulk-upload",
      "upload-files-2",
      "attach-reports",
      "assign-report",
      "view-login-history",
      "change-password",
      "create-cms-user",
      "report-assignment",
    ],
    FORMATTING_EXECUTIVE: [
      "home",
      "dashboard",
      "view-and-edit-reports",
      "create-report",
      "upload-files-1",
      "bulk-upload",
      "upload-files-2",
      "attach-reports",
      "assign-report",
      "view-login-history",
      "change-password",
    ],
  };

  if (token) {
    if (roleAccessibility[role].includes(path)) {
      return children;
    } else {
      return <Navigate to="/not-authorized" />;
    }
  } else {
    return <Navigate to="/" />;
  }
}

export default PrivateRoutes;
