import React, { useState } from "react";
import { Card, Button, Form, Input, message, Descriptions, Tag } from "antd";
import axios from "axios";
import API from "../../utils/api";

function Actions() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const { prodgainApiBaseUrl, prodgainAuthToken } = API;

  const fetchReportStatus = async (uploadId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${prodgainApiBaseUrl}/report/${uploadId}`,
        {
          headers: {
            Authorization: prodgainAuthToken,
          },
        }
      );

      const { data } = response.data;

      if (data.status === "processed") {
        setReportData(data);
        message.success("Report status fetched successfully.");
      } else {
        message.error("Report is not processed yet. Please try again later.");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Failed to fetch report status."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action) => {
    setLoading(true);
    try {
      const endpointMap = {
        "Push TOC": `/actions/push-toc/${reportData.uploadId}`,
        "Push Executive Summary": `/actions/push-exec-summary/${reportData.uploadId}`,
        "Push Snippets": `/actions/push-snippets/${reportData.uploadId}`,
        "Push Dashboard": `/actions/push-dashboard/${reportData.uploadId}`,
        "Push All Graphs": `/actions/push-all-graphs/${reportData.uploadId}`,
      };

      const response = await axios.post(
        `${prodgainApiBaseUrl}${endpointMap[action]}`,
        {},
        {
          headers: {
            Authorization: prodgainAuthToken,
          },
        }
      );

      if (action === "Push All Graphs" && response.status === 207) {
        const { totalGraphs, successfulPushes, failedPushes } =
          response.data.data;
        message.info(
          `Graphs pushed: ${successfulPushes}/${totalGraphs}. Failed pushes: ${failedPushes}.`
        );
      } else {
        message.success(
          response.data.message || `${action} completed successfully.`
        );
      }

      await fetchReportStatus(reportData.uploadId); // Recheck status after pushing
    } catch (error) {
      message.error(error.response?.data?.message || `Failed to ${action}.`);
    } finally {
      setLoading(false);
    }
  };

  const renderPushedStatus = (status) => (
    status ? <Tag color="green">Pushed</Tag> : <Tag color="red">Not Pushed</Tag>
  );

  return (
    <Card title="Actions" style={{ marginBottom: "20px" }}>
      <Form
        layout="inline"
        form={form}
        onFinish={({ uploadId }) => fetchReportStatus(uploadId)}
      >
        <Form.Item
          label="Upload ID"
          name="uploadId"
          rules={[{ required: true, message: "Please input the upload ID!" }]}
        >
          <Input placeholder="Enter Upload ID" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Check Status
          </Button>
        </Form.Item>
      </Form>

      {reportData && (
        <Descriptions
          bordered
          column={1}
          size="middle"
          style={{ marginTop: "20px" }}
        >
          <Descriptions.Item label="Report Name">
            {reportData.reportName}
          </Descriptions.Item>
          <Descriptions.Item label="Upload ID">
            {reportData.uploadId}
          </Descriptions.Item>
          <Descriptions.Item label="Report ID">
            {reportData.reportId}
          </Descriptions.Item>

          <Descriptions.Item label="Actions">
            <Descriptions bordered size="middle" column={1}>
              {[
                { label: "Push TOC", key: "pushToc" },
                { label: "Push Executive Summary", key: "pushExecSummary" },
                { label: "Push Snippets", key: "pushSnippets" },
                { label: "Push Dashboard", key: "pushDashboard" },
                { label: "Push All Graphs", key: "pushGraphs" },
              ].map((action) => (
                <Descriptions.Item key={action.key} label={action.label}>
                  {renderPushedStatus(
                    reportData?.pushedToMordor?.[action.key]
                  )}
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px" }}
                    // disabled={reportData?.pushedToMordor?.[action.key]}
                    onClick={() => handleAction(action.label)}
                  >
                    Push
                  </Button>
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
}

export default Actions;
